import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { api } from '@iblai/ibl-web-react-common';
import { Link, useLocation } from 'react-router-dom';

import Footer from '../../components/Footer/Footer';
import useNotification from '../../hooks/useNotification';
import Notifications from '../../components/notifications';
import { defaultSPALogoData, validateEmail } from '../../helpers';

import '../../assets/css/normalize.css';
import '../../assets/css/webflow.css';
import '../../assets/css/ibl-default-login.webflow.css';
import '../../assets/css/custom.css';
import 'jquery-ui';
import StaticHeader from '../../components/StaticHeader/StaticHeader';

const ERROR_MESSAGES = {
  EMAIL_REQUIRED: 'This field is required',
  EMAIL_INVALID: 'Must be a valid email',
};

const ForgotPassword = () => {
  const [notifications, notify, onClose] = useNotification();
  const location = useLocation();
  const [redirectTo, setRedirectTo] = useState(null);
  const { t } = useTranslation();

  // email form state
  const [email, setEmail] = useState('');
  const [showEmailError, setShowEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');

  // form state
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const _redirectTo = queryParams.get('redirect-to');
    setRedirectTo(_redirectTo);
    api.ibledxplatform.getCsrfToken();
  }, []);

  const handleResetForm = () => {
    setEmail('');
    setIsSubmitting(false);
    showEmailError(false);
    emailErrorMessage('');
  };

  const handleForgotPasswordSubmit = ({ email, handleResetForm, notify }) => {
    api.iblwebauth.forgetPassword(
      {
        email: String(email).toLowerCase(),
      },
      (data) => {
        const queryParams = new URLSearchParams(location.search);
        const _redirectTo = queryParams.get('redirect-to');

        const notificationMsg =
          'Reset Password Initiated. Kindly check your email address and follow instructions.';

        // redirect user to login page. attach notification message to redirect URL
        window.location.href = `/login?redirect-to=${_redirectTo}&enforce-login=1&notification-message=${encodeURI(
          notificationMsg
        )}`;
      },
      (error) => {
        handleResetForm();
        try {
          notify(
            'An Error Occurred',
            Object.values(error?.responseJSON?.message).join('\n'),
            'error'
          );
        } catch (e) {
          notify('An Error Occurred', 'Please try again later', 'error');
        }
      }
    );
  };

  const handleSubmit = (formEvent) => {
    formEvent.preventDefault();

    setShowEmailError(false);
    setEmailErrorMessage('');
    setIsSubmitting(true);

    // Check if email field is empty
    if (!email || email.length === 0) {
      setEmailErrorMessage(ERROR_MESSAGES.EMAIL_REQUIRED);
      setShowEmailError(true);
      setIsSubmitting(false);
      return;
    }

    // Check if email is valid
    if (!validateEmail(email)) {
      setEmailErrorMessage(ERROR_MESSAGES.EMAIL_INVALID);
      setShowEmailError(true);
      setIsSubmitting(false);
      return;
    }

    // Email is valid, submit form
    handleForgotPasswordSubmit({
      email: String(email).trim(),
      handleResetForm,
      notify,
    });

    return;
  };

  const STATIC_HEADER_ENABLED =
    process.env.REACT_APP_IBL_STATIC_HEADER_ENABLED === 'true';

  return (
    <>
      <div className="w-layout-vflex auth-page-container">
        {STATIC_HEADER_ENABLED && <StaticHeader />}
        <div className="w-layout-vflex auth-page-block">
          {!STATIC_HEADER_ENABLED && (
            <img
              src={defaultSPALogoData.logo}
              loading="lazy"
              alt=""
              className="auth-logo-img"
            />
          )}
          <div className="w-layout-vflex auth-page-main-content">
            <h2 className="auth-page-title smaller">
              {t('Forgot your password')}
            </h2>
            <div className="auth-title-desc">
              Enter your email address and we will send you instructions to
              reset your password.
            </div>
            <div className="auth-form-block w-form">
              <form onSubmit={handleSubmit} className="auth-form">
                <div
                  data-auth-level="1"
                  className="w-layout-vflex auth-input-group visible"
                >
                  <input
                    className={`auth-input email w-input lowercased-layout`}
                    autoFocus
                    maxLength="256"
                    name="email"
                    value={email}
                    onChange={(inputEvent) => setEmail(inputEvent.target.value)}
                    data-name="Email"
                    placeholder="Email address"
                    type="email"
                  />
                  {showEmailError && (
                    <div className="text-error">{emailErrorMessage}</div>
                  )}
                </div>
                <button
                  className={`w-layout-hflex auth-submit-btn ${
                    isSubmitting ? 'disabled' : ''
                  }`}
                  type="submit"
                >
                  <div className="auth-submit-btn-label">
                    {isSubmitting ? 'Submitting' : 'Continue'}
                  </div>
                  {isSubmitting && (
                    <div className="btn-spinner-block w-embed">
                      <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  )}
                </button>
              </form>
            </div>
            <div className="w-layout-hflex auth-redirect-block">
              <div className="text-block-31">Already have an account?</div>
              <Link
                to={`/login?redirect-to=${redirectTo}`}
                className="auth-link-redirect"
              >
                Log In
              </Link>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      {Object.entries(notifications).map(([key, notificationObj]) => (
        <Notifications
          onClose={() => onClose(key)}
          key={key}
          displayMsg={notificationObj.displayMsg}
          headerMsg={notificationObj.headerMsg}
          type={notificationObj.type}
        />
      ))}
    </>
  );
};

export default ForgotPassword;
