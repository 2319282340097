import { useState } from 'react';

import eye from '../../assets/images/eye-1.svg';
import eyeOff from '../../assets/images/eye-off.svg';

export function PasswordsScreen({
  t,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  showPasswordError,
  passwordErrorMessage,
  confirmPasswordErrorMessage,
  showConfirmPasswordError,
}) {
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);

  const togglePasswordVisibility = () => {
    setIsPasswordHidden(!isPasswordHidden);
  };

  return (
    <>
      <div className="w-layout-vflex auth-input-group visible">
        <input
          className="auth-input password-field password w-input"
          maxLength="256"
          name="password"
          placeholder="Password"
          type={isPasswordHidden ? 'password' : 'text'}
          value={password}
          onChange={(inputEvent) => setPassword(inputEvent.target.value)}
        />
        {showPasswordError && (
          <div className="text-error">{passwordErrorMessage}</div>
        )}
        <div
          className="w-layout-hflex password-eye-block"
          onClick={togglePasswordVisibility}
        >
          <img
            src={isPasswordHidden ? eye : eyeOff}
            loading="lazy"
            alt={isPasswordHidden ? 'Show password' : 'Hide password'}
            className="password-eye-icon"
          />
        </div>
      </div>
      <div className="w-layout-vflex auth-input-group visible">
        <input
          className="auth-input password-field password-confirm w-input"
          maxLength="256"
          placeholder="Confirm password"
          type={isPasswordHidden ? 'password' : 'text'}
          value={confirmPassword}
          onChange={(inputEvent) => setConfirmPassword(inputEvent.target.value)}
        />
        {showConfirmPasswordError && (
          <div className="text-error">{confirmPasswordErrorMessage}</div>
        )}
        <div
          className="w-layout-hflex password-eye-block"
          onClick={togglePasswordVisibility}
        >
          <img
            src={isPasswordHidden ? eye : eyeOff}
            loading="lazy"
            alt={isPasswordHidden ? 'Show password' : 'Hide password'}
            className="password-eye-icon"
          />
        </div>
      </div>
    </>
  );
}
