import { useTranslation } from 'react-i18next';

export function SubmitButton({ isSubmitting }) {
  const { t } = useTranslation();

  return (
    <button
      className={`w-layout-hflex auth-submit-btn ${
        isSubmitting ? 'disabled' : ''
      }`}
      type="submit"
      aria-label="Continue"
      disabled={isSubmitting}
    >
      {isSubmitting ? (
        <>
          <div className="auth-submit-btn-label">Submitting</div>
          <div className="btn-spinner-block w-embed">
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </>
      ) : (
        <div className="auth-submit-btn-label">{t('Continue')}</div>
      )}
    </button>
  );
}
