export function EmailUsernameScreen({
  email,
  setEmail,
  showEmailError,
  emailErrorMessage,
  emailExample,
  username,
  setUsername,
  showUsernameError,
  usernameErrorMessage,
}) {
  return (
    <>
      <div className="w-layout-vflex auth-input-group visible">
        <input
          className="auth-input email w-input lowercased-layout"
          autoFocus={true}
          maxLength="256"
          placeholder="Email address"
          type="email"
          value={email}
          onChange={(inputEvent) => setEmail(inputEvent.target.value)}
        />
        {showEmailError && (
          <div className="text-error">{emailErrorMessage}</div>
        )}
        <div className="text-helper">e.g. {emailExample}</div>
      </div>
      <div
        data-auth-level="1"
        className="w-layout-vflex auth-input-group visible"
      >
        <input
          className="auth-input username w-input lowercased-layout"
          maxLength="256"
          placeholder="Username"
          type="text"
          value={username}
          onChange={(inputEvent) => setUsername(inputEvent.target.value)}
        />
        {showUsernameError && (
          <div className="text-error">{usernameErrorMessage}</div>
        )}
        <div className="text-helper">e.g. amazingteacher</div>
      </div>
    </>
  );
}
